
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { PersonView } from '@/components/forms/view/PersonView'
  import { Person } from '@/entities/persons'
  import GDatePicker from '@/components/core/input/GDatePicker.vue'
  import AddressAlignment from '@/components/person/AddressAlignment.vue'
  import { plainToInstance } from 'class-transformer'
  import { isEmpty } from '@/utils/general'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import { ConsultRutInterface } from '@/store/persons/state'
  import BankDataAlignment from '@/components/person/BankDataAlignment.vue'
  import { Address, bankDataFull } from '@/store/persons/person'
  import FieldTitle from '@/components/forms/fields/FieldTitle.vue'
  import GTimeSelector from '@/components/core/input/GTimeSelector.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import GPhone from '@/components/core/input/GPhone.vue'
  import GRut from '@/components/core/input/GRut.vue'
  import GoogleMap from '@/components/global/GoogleMap.vue'
  import { Debounce } from '@/utils/decorators'
  import { PositionType } from '@/entities/hr'

@Component({
  components: {
    GRut,
    GPhone,
    GFiles,
    GTimeSelector,
    FieldTitle,
    BankDataAlignment,
    GAlert,
    AddressAlignment,
    GoogleMap,
    GDatePicker,
  },
  methods: {},
})
  export default class CompanyFields extends PersonView {
  @Prop({ type: Object, default: () => ({}) }) value!: Person
  @Prop({ type: String, default: null }) type!: string

  disabled = false
  openSchedule = false
  phoneLaborError = false
  openSocialNetwork = false
  schedule = []
  filesParameters = []
  idProcess = null
  socialNetwork = []
  mapUrl=''
  person = plainToInstance(Person, {})
  errorMessage = {
    rut: '',
    socialReason: '',
    alias: '',
    positionTypes: '',
  }

  socialNetworkData = {
    facebook: '',
    linkedin: '',
    instagram: '',
    x: '',
    tiktok: '',
  }

  scheduleData = {
    mondayFriday: {
      start: '',
      end: '',
    },
    saturday: {
      start: '',
      end: '',
    },
    sunday: '',
  }

  formData = {
    rut: '',
    socialReason: '',
    alias: '',
    companyType: null,
    phoneWork: '',
    logo: [],
    facade: [],
    email: '',
    webSite: '',
    birthDate: '',
    schedule: null,
    members: [],
    contactPerson: [],
    charterAttachment: [],
    currentValidityAttachment: [],
    taxInformation: [],
    bankData: [],
    extractAttachment: [],
    addresses: [],
    officialPublishAttachment: [],
    positionTypes: [],
  }

  fields = {
    companyType: {
      items: [],
    },
    logo: {
      properties: {
        accept: '',
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        icon: '',
      },
    },
    facade: {
      properties: {
        accept: '',
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        icon: '',
      },
    },
  }

  alert = {
    open: false,
    title: '',
    subtitle: '',
  }

  alertFound = {
    open: false,
    title: '',
    subtitle: '',
  }

  alertRemoveDirection = {
    open: false,
    title: '',
    subtitle: '',
    address: null,
  }

  alertRemoveBankData = {
    open: false,
    title: '',
    subtitle: '',
    bankData: null,
  }

  alertRemovePosition = {
    open: false,
    title: '',
    subtitle: '',
    position: null,
  }

  declare $refs: {
    form: HTMLFormElement
    phoneWork: GPhone
  };

  // Methods
  async mounted () {
    this.filesParameters = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { _and: [{ process: { table_name: { _eq: 'person' } } }] },
    })

    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'person' } },
    })

    this.setProperties(this.filesParameters, 'logo', 'logo')
    this.setProperties(this.filesParameters, 'facade_photo', 'facade')
    this.idProcess = process[0].id
    this.fields.companyType.items = await this.fetchData({
      query: { name: 'find', model: 'CompanyType', order: { description: 'asc' } },
    })

    this.setTheBackup()
    await this.setPositionTypes()
  }

  setTheBackup () {
    const { backup } = this
    if (!backup) return
    const companyForm = 'companyForm' in backup && backup.companyForm

    if (companyForm) {
      this.formData = backup.companyForm
    }
  }

  async setPositionTypes () {
    if (this.isSysOps) {
      this.formData.positionTypes = await this.fetchData({
        query: { name: 'find', model: 'PositionType' },
        force: true,
      })
    }
  }

  async validSocialReason (val: string) {
    if (!val?.length) return

    const person = await this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: { company_name: { _eq: val.toUpperCase() } },
      force: true,
    })

    if (person?.length) {
      this.errorMessage.socialReason = `La razón social ${val} ya existe`
    } else {
      this.errorMessage.socialReason = ''
    }
  }

  async validAlias (val: string) {
    if (!val?.length) return

    const person = await this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: { alias: { _eq: val.toUpperCase() } },
      force: true,
    })

    if (person?.length) {
      this.errorMessage.alias = `El nombre de fantasía ${val} ya existe`
    } else {
      this.errorMessage.alias = ''
    }
  }

  async searchRut (rut: string) {
    if (!rut?.length || rut?.length < 8) return null

    this.findPerson({ rut, type: this.type })
  }

  async send () {
    const phoneWork = this.$refs.phoneWork
    const someErrorMessage = this.errorMessage.rut?.length || this.errorMessage.socialReason?.length || this.errorMessage.alias?.length

    if (!this.$refs.form.validate() || !phoneWork.$refs.form.validate() || this.phoneLaborError || someErrorMessage) {
      await this.setFieldError()
      return
    }
    this.loadingForm = true
    const { type, formData } = this

    const person = await this.createUpdatePerson(null, formData, type)
    if (this.formData?.logo?.length) {
      const file = await this.handleFileType(this.formData.logo, { properties: this.fields.logo.properties }, this.idProcess, person.id)
      const link = file?.[0]?.file?.uri

      if (link) {
        await this.pushData({
          model: 'Person',
          fields: {
            id: person.id,
            photo: link,
          },
        })
      }
    }
    if (this.formData?.facade?.length) {
      await this.handleFileType(this.formData.facade, { properties: this.fields.facade.properties }, this.idProcess, person.id)
    }

    if (person?.type?.name === 'company') {
      this.emitEmbed(person)
    }
    await this.close()
  }

  initValues () {
    this.initCompany()
    this.setFoundPerson(null)
    this.setRutInfo(null)
  }

  initCompany () {
    this.formData = {
      rut: '',
      socialReason: '',
      alias: '',
      companyType: '',
      webSite: '',
      phoneWork: '',
      logo: [],
      facade: [],
      email: '',
      birthDate: '',
      schedule: null,
      contactPerson: [],
      charterAttachment: [],
      currentValidityAttachment: [],
      taxInformation: [],
      bankData: [],
      members: [],
      extractAttachment: [],
      addresses: [],
      officialPublishAttachment: [],
      positionTypes: [],
    }
    this.disabled = false
  }

  async ediBankData (bankData) {
    const { person, value, formData, type } = this

    let id = value?.id || person?.id
    const personAccount = await this.fetchData({
      query: { name: 'find', model: 'PersonAccount' },
      filter: { id: { _eq: bankData.idPersonAccount } },
    })

    const newPerson = await this.createUpdatePerson(id, formData, type)

    await this.$router.replace({ params: { id: newPerson?.id.toString() } }).catch(() => {
    })

    this.saveBackup()
    id = newPerson?.id || id
    if (id) {
      await this.$router.push({
        name: 'person-bank-data',
        params: {
          model: 'Person',
          uid: id.toString(),
          id: personAccount[0].bankData.id,
        },
      })
    }
  }

  async openBankData (): Promise<void> {
    const { person, value, formData, type } = this

    let id = value?.id || person?.id

    const newPerson = await this.createUpdatePerson(id, formData, type)

    await this.$router.replace({ params: { id: newPerson?.id.toString() } }).catch(() => {
    })

    this.saveBackup()
    id = newPerson?.id || id
    if (id) {
      await this.$router.push({
        name: 'person-bank-data',
        params: {
          model: 'Person',
          uid: id.toString(),
          id: 'create',
        },
      }).catch(() => {
      })
    }
  }

  setProperties (fileInfo, fileTypeName, fieldKey) {
    if (!fileInfo?.length) return
    const info = fileInfo.find(fileParameter => fileParameter.name === fileTypeName)

    if (info) {
      this.fields[fieldKey].properties.accept = info.fileType.mimes
      this.fields[fieldKey].properties.multiple = info.multiple
      this.fields[fieldKey].properties.fileTypeId = info.fileType.id
      this.fields[fieldKey].properties.name = info.name
      this.fields[fieldKey].properties.label = info.description
      this.fields[fieldKey].properties.required = info.required
      this.fields[fieldKey].properties.icon = info.fileType.icon
    }
  }

  async openDirectionData (): Promise<void> {
    const { person, value, formData, type } = this

    let id = value?.id || person?.id

    const newPerson = await this.createUpdatePerson(id, formData, type)
    await this.$router.replace({ params: { id: newPerson?.id.toString() } }).catch(() => {
    })
    id = newPerson?.id || id
    this.saveBackup()
    if (id) {
      await this.$router.push({
        name: 'person-address-data',
        params: {
          model: 'Person',
          uid: id.toString(),
          id: 'create',
        },
      })
    }
  }

  removeDirection (direction: Address): void {
    this.alertRemoveDirection = {
      open: true,
      title: '¿Estás seguro de que quieres desvincular esta dirección?',
      subtitle: `${direction.streetName} ${direction.streetNumber}, ${direction.cityName}, ${direction.regionAbbreviation}/${direction.addressType}`,
      address: direction,
    }
  }

  async confirmRemoveDirection () {
    this.alertRemoveDirection.open = false
    await this.addressUpdateStatus(this.alertRemoveDirection.address.idPersonAddress)
    this.formData.addresses = await this.getAddressFormatted(this.personEntity)
    this.$emit('update')
  }

  removeBankData (bankData: bankDataFull): void {
    this.alertRemoveBankData = {
      open: true,
      title: '¿Estás seguro de que quieres desvincular este dato bancario?',
      subtitle: `${bankData.accountNumber}/${bankData.bank}/${bankData.accountType}`,
      bankData,
    }
  }

  async confirmRemoveBankData () {
    this.alertRemoveBankData.open = false
    await this.updateAccountState(this.alertRemoveBankData.bankData.idPersonAccount)
    this.$emit('update')
  }

  confirmChange () {
    this.alert = {
      open: false,
      title: '',
      subtitle: '',
    }
    this.$emit('change-type')
  }

  confirm () {
    this.alertFound = {
      open: false,
      title: '',
      subtitle: '',
    }
    this.formData.rut = this.rutInfo.rut
    this.formData.socialReason = this.rutInfo.otherNames
  }

  cancelChange () {
    this.alert = {
      open: false,
      title: '',
      subtitle: '',
    }
    this.initCompany()
  }

  saveBackup () {
    const { backup, formData } = this
    if (backup) {
      backup.companyForm = formData
      this.setBackup(backup)
    } else {
      this.setBackup({
        companyForm: { ...formData },
      })
    }
  }

  emitEmbed (newPerson) {
    const { typeInfo, backup } = this

    if (backup) {
      backup[typeInfo] = newPerson
      this.setBackup(backup)
    } else {
      this.setBackup({ [typeInfo]: newPerson })
    }

    this.embedPerson({ type: typeInfo, person: newPerson })
  }

  // Getters
  get personCreatable (): boolean {
    const { formData, errorMessage: { rut } } = this
    return (
      !isEmpty(formData.rut) &&
      !isEmpty(formData.socialReason) &&
      !isEmpty(formData.alias) &&
      !isEmpty(formData.companyType) &&
      isEmpty(rut)
    )
  }

  get iconSchedule () {
    const { schedule } = this

    if (!schedule?.length) {
      return 'mdi-plus-circle-outline'
    }

    return 'mdi-pencil-outline'
  }

  get iconSocial () {
    const { socialNetwork } = this

    const keys = Object.keys(socialNetwork)
    if (!keys?.length) {
      return 'mdi-plus-circle-outline'
    }

    return 'mdi-pencil-outline'
  }

  Watchers
  @Debounce()
  @Watch('isSysOps', { immediate: true, deep: true })
  async updatePositionTypes () {
    await this.setPositionTypes()
  }

  @Watch('value', { immediate: true, deep: true })
  async onValueChanged (person: Person) {
    if (!person) return

    if (person.uid) {
      this.disabled = true
    }

    const members = await this.fetchData({
      query: { name: 'findLite', model: 'Employee' },
      filter: { process_status: { status: { name: { _eq: 'active' } } } },
      force: true,
    })

    await Promise.all(members.map(async member => {
      const person = await this.fetchData({
        query: { name: 'find', model: 'Person' },
        filter: { employee: { id: { _eq: member.id } } },
        force: true,
      })

      const index = members.findIndex(m => m.id === member.id)
      members[index].person = person?.[0]
    }))

    this.schedule = person?.metadata?.schedule
    this.setSocialNetworkData(person?.metadata?.socialNetwork)
    this.setScheduleData(person?.metadata?.schedule)
    this.setMap(person?.metadata?.mapUrl)
    this.formData.contactPerson = person?.metadata?.contactPerson
    this.formData.phoneWork = person?.secondaryPhone?.work
    this.formData.members = members
    this.formData.rut = person.uid
    this.formData.alias = person.alias
    this.formData.socialReason = person.companyName
    this.formData.webSite = person.webSite
    this.formData.email = person.email?.personal || person.email?.work
    this.formData.birthDate = person?.birthDate?.format('YYYY-MM-DD')
    this.formData.companyType = person?.companyType?.id
    this.formData.addresses = await this.getAddressFormatted(person)
    this.formData.bankData = this.getBankDataFormatted(person)

    await this.getFiles(person)
  }

  async getFiles (person) {
    const { idProcess } = this
    if (!person?.id) return
    const files = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: person.id } },
          { parameter: { process: { id: { _eq: idProcess } } } },
        ],
      },
      force: true,
    })

    this.formData.logo = files.filter(file => file.parameter.name === 'logo')
    this.formData.facade = files.filter(file => file.parameter.name === 'facade_photo')
  }

  setScheduleData (schedule) {
    if (!schedule) return
    this.scheduleData.mondayFriday.start = schedule[0].start
    this.scheduleData.mondayFriday.end = schedule[0].end
    this.scheduleData.saturday.start = schedule[1].start
    this.scheduleData.saturday.end = schedule[1].end
    this.scheduleData.sunday = schedule[2].end
  }

  setMap (map) {
    this.mapUrl = map
  }

  setSocialNetworkData (socialNetwork) {
    if (!socialNetwork) return

    this.socialNetwork = [{
      name: 'Facebook',
      link: socialNetwork?.facebook || '',
    }, {
      name: 'LinkedIn',
      link: socialNetwork?.linkedin || '',
    }, {
      name: 'Instagram',
      link: socialNetwork?.instagram || '',
    }, {
      name: 'X',
      link: socialNetwork?.x || '',
    }, {
      name: 'Tiktok',
      link: socialNetwork?.tiktok || '',
    }]

    this.socialNetworkData.facebook = socialNetwork?.facebook || ''
    this.socialNetworkData.linkedin = socialNetwork?.linkedin || ''
    this.socialNetworkData.instagram = socialNetwork?.instagram || ''
    this.socialNetworkData.x = socialNetwork?.x || ''
    this.socialNetworkData.tiktok = socialNetwork?.tiktok || ''
  }

  getBankDataFormatted (person) {
    return person?.accounts?.map(account => {
      return {
        idPersonAccount: account.id,
        accountNumber: account.bankData.accountNumber,
        bank: account.bankData.bank.alias,
        accountType: account.bankData.accountType.description,
      }
    })
  }

  async createPosition () {
    this.saveBackup()
    this.initValues()
    await this.$router.push({
      name: 'generic-sysop-position',
    }).catch(() => {
    })
  }

  async editPosition (position) {
    this.saveBackup()
    this.initValues()
    await this.$router.push({
      name: 'generic-sysop-position',
      params: { uid: position.id },
    }).catch(() => {})
  }

  async removePosition (position: PositionType) {
    const employeePositions = await this.fetchData({
      query: { name: 'find', model: 'EmployeePosition' },
      filter: { id_position_type: { _eq: position.id } },
      force: true,
    })

    if (employeePositions.length) {
      this.errorMessage.positionTypes = `No se puede eliminar un cargo que ha tenido uno o más integrantes asociados`
    } else {
      this.alertRemovePosition = {
        open: true,
        title: '¿Está seguro de eliminar este cargo?',
        subtitle: `${position.name}: ${position.description}`,
        position,
      }
    }
  }

  async confirmRemovePosition (position) {
    this.alertRemovePosition = {
      open: false,
      title: '',
      subtitle: '',
      position: undefined,
    }

    await this.removeData({
      model: 'PositionType',
      fields: {
        id: position.id,
      },
    })

    await this.setPositionTypes()
  }

  async createMember () {
    this.saveBackup()
    this.initValues()
    await this.$router.push({
      name: 'generic-sysop-employee',
    }).catch(() => {
    })
  }

  async editMember (member) {
    this.saveBackup()
    this.initValues()

    await this.$router.push({
      name: 'generic-sysop-employee',
      params: { uid: member.id },
    }).catch(() => {
    })
  }

  async getAddressFormatted (person) {
    if (!person?.id) return []
    const addresses = await this.fetchData({
      query: { name: 'find', model: 'PersonAddress' },
      filter: { _and: [{ id_person: { _eq: person.id } }, { active: { _eq: true } }] },
      force: true,
    })

    return addresses?.map(personAddress => {
      return {
        idAddress: personAddress.address.id,
        streetName: personAddress.address.streetName,
        streetNumber: personAddress.address.streetNumber,
        idCity: personAddress.address.city.id,
        idPersonAddress: personAddress.id,
        cityName: personAddress.address.city.name,
        idRegion: personAddress.address.city.region.id,
        regionAbbreviation: personAddress.address.city.region.abbreviation,
        addressType: personAddress.type.description,
      }
    }) || []
  }

  validNames (val) {
    if (!val) return false
    const keys = ['firstName', 'firstLastName', 'secondName', 'secondLastName', 'otherNames']
    const valid = keys.every(key => val[key] !== null && val[key] !== undefined)

    return !valid
  }

  @Watch('rutInfo', { immediate: true, deep: true })
  onRutInfoChanged (val: ConsultRutInterface) {
    if (!val || this.validNames(val)) {
      this.formData.rut = val?.rut
      return
    }
    const { backup } = this
    if ('companyForm' in backup && backup.companyForm) {
      return
    }

    if (isEmpty(val.otherNames)) {
      this.alert = {
        open: true,
        title: `Segun nuestro sistema el rut ${val.rut} es una persona de tipo natural de nombre`,
        subtitle: `${val.firstName} ${val.firstLastName} ${val.secondName} ${val.secondLastName}`,
      }
    } else {
      this.alertFound = {
        open: true,
        title: `Segun nuestro sistema el rut ${val.rut} pertenece a:`,
        subtitle: `${val.otherNames}`,
      }
    }
  }

  async saveSocialNetwork () {
    if (!this.$refs.formSocialNetwork.validate()) return
    this.openSocialNetwork = false

    const { formData: { rut } } = this

    this.socialNetwork = [{
      name: 'Facebook',
      link: this.socialNetworkData?.facebook || '',
    }, {
      name: 'LinkedIn',
      link: this.socialNetworkData?.linkedin || '',
    }, {
      name: 'Instagram',
      link: this.socialNetworkData?.instagram || '',
    }, {
      name: 'X',
      link: this.socialNetworkData?.x || '',
    }, {
      name: 'Tiktok',
      link: this.socialNetworkData?.tiktok || '',
    }]

    const existPerson = await this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: { uid: { _eq: rut } },
      force: true,
    })

    const fields = {
      id: existPerson?.[0]?.id,
      metadata: {
        ...existPerson[0]?.metadata,
        socialNetwork: this.socialNetworkData,
      },
    }

    await this.pushData({
      model: 'Person',
      fields,
    })
  }

  async saveSchedule () {
    if (!this.$refs.formSchedule.validate()) return
    this.openSchedule = false
    const { formData: { rut } } = this
    this.schedule = [
      {
        day: 'Lunes - Viernes',
        start: this.scheduleData.mondayFriday.start,
        end: this.scheduleData.mondayFriday.end,
      },
      {
        day: 'Sabado',
        start: this.scheduleData.saturday.start,
        end: this.scheduleData.saturday.end,
      },
      {
        day: 'Domingo',
        start: '',
        end: this.scheduleData.sunday,
      },
    ]

    const existPerson = await this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: { uid: { _eq: rut } },
      force: true,
    })

    const fields = {
      id: existPerson?.[0]?.id,
      metadata: {
        ...existPerson[0]?.metadata,
        schedule: this.schedule,
      },
    }

    await this.pushData({
      model: 'Person',
      fields,
    })
  }

  phoneLabor (flag: boolean) {
    this.phoneLaborError = flag
  }

  get isSysOps () {
    const { roles, value } = this
    if (value?.type?.name !== 'system') {
      return false
    }
    return roles?.some(role => role.slug === 'sysop')
  }

  @Watch('personIdComputed', { immediate: true })
  onPersonIdComputed (val) {
    if (!val || isNaN(val)) {
      this.initCompany()
    }
  }

  @Watch('mapUrl', { immediate: true })
  @Debounce()
  async handleMapChange (val) {
    if (!val?.length || !this.isSysOps) return
    const existPerson = await this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: { uid: { _eq: this.formData.rut } },
      force: true,
    })

    const fields = {
      id: existPerson?.[0]?.id,
      metadata: {
        ...existPerson[0]?.metadata,
        mapUrl: val,
      },
    }

    await this.pushData({
      model: 'Person',
      fields,
    })
  }
  }
